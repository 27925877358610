import * as React from 'react';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';


import { toast, Slide } from 'react-toastify';
import configAxios from '../service/configAxios';

export default function Hero() {
  const [email, setEmail] = React.useState('');

  return (
    <Container id="hero" sx={{ pt: { xs: 2, sm: 6 } }}>
      <Grid container spacing={6}>
        <Grid item xs={10} md={12}>
          <div>
            <Typography component="h2" variant="h2" color="text.primary">
            Phorm para todos
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ mb: { xs: 2, sm: 2 }, mt: { xs: 2, sm: 4 }, fontSize: 18 }}>
                <p>Entre em contato conosco para sanar mais duvidas em nossos canais </p>
               
            </Typography>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}
