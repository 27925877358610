import * as React from 'react';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
// import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { toast, Slide } from 'react-toastify';
import configAxios from '../service/configAxios';
import TextField from '@mui/material/TextField';

export default function PartnersContact() {

    const [email, setEmail] = React.useState('');

    function handleSubmit(event) {
        event.preventDefault();
        if (!email) {
          toast.warn('Por favor, preencha o seu email', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: 'light',
            transition: Slide
          });
          return;
        }
        const lead = {
          name: 'Site de vendas',
          email: email,
          subject: 'Captação através do site de vendas',
          message: 'Entrar em contato com o email',
          created_at: Date.now()
        };
    
        configAxios
          .post(`/sendEmail/contact`, lead)
          .then(() => {
            toast.success('Você solicitou com sucesso o nosso contato!', {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: 'light',
              transition: Slide
            });
            setEmail();
          })
          .catch(() => {
            toast.warn('Não foi possível solicitar o contato, por favor tente novamente!', {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: 'light',
              transition: Slide
            });
          });
    }
    
    function handleInputChange(event) {
    setEmail(event.target.value);
    }

  return (
    <Container id="PartnersContact" sx={{ pt: { xs: 2, sm: 0 } }}>
      <Grid container spacing={6}>
        <Grid item xs={10} md={12}>
          <div>
            <Typography component="h2" variant="h2" color="text.primary">
            Phorm para parceiros
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ mb: { xs: 2, sm: 2 }, mt: { xs: 2, sm: 4 }, fontSize: 18 }}>
                <p>Bem-vindo à Phorm, </p>
               
                <p>Junte-se a nós e seja parte da revolução fitness no Brasil. Phorm - Saúde para todos.</p>
            </Typography>
          </div>
        </Grid>

        <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          // pt: { xs: 14, sm: 20 },
          pb: { xs: 6, sm: 0 }
        }}>
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
          <Typography
            variant="h2"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignSelf: 'center',
              textAlign: 'center',
              fontSize: 'clamp(3.5rem, 10vw, 45)',
            }}>
            Quer fazer parte da rede de parceiros do Phorm?
          </Typography>
          <Typography
            textAlign="center"
            color="text.secondary"
            sx={{ alignSelf: 'center', width: { sm: '100%', md: '80%' } }}>
             Junte-se à nossa comunidade de líderes em saúde e bem-estar! Informe seu e-mail e permita que nossos consultores especializados entrem em contato com você. Rumo ao próximo nível.  
          </Typography>
          <form
            style={{
              alignSelf: 'center'
            }}
            onSubmit={handleSubmit}>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              alignSelf="center"
              spacing={1}
              useFlexGap
              sx={{ pt: 0, width: { xs: '100%', sm: 'auto' } }}>
              <TextField
                id="outlined-basic"
                hiddenLabel
                size="small"
                variant="outlined"
                aria-label="Digite o seu melhor e-mail"
                placeholder="Digite o seu melhor e-mail"
                onChange={(event) => handleInputChange(event)}
                type="email"
                value={email || ''}
                required
                inputProps={{
                  autoComplete: 'off',
                  'aria-label': 'Digite o seu email'
                }}
              />
              <Button variant="outlined" color="primary" type="submit">
                Quero ser Phorm!
              </Button>
            </Stack>
          </form>
          <Typography variant="caption" textAlign="center" sx={{ opacity: 0.8 }}>
            Ao clicar em &quot;Quero ser Phorm!&quot; você autoriza que entremos em contato
            para realizar a demonstração e prosseguirmos com essa parceria.
          </Typography>
        </Stack>
      </Container>

      </Grid>
    </Container>
  );
}
